// import lunr
import lunr from 'lunr';

import { deburr, concat, uniq } from 'lodash';

// Define a pipeline function that converts 'gray' to 'grey'
var pipelineFunction = function (token) {
	return token.update(function () { 
		return deburr( token.toString() );  
	})
}

// Register the pipeline function so the index can be serialised
lunr.Pipeline.registerFunction(pipelineFunction, 'pipe')

var pipe = function (builder) {

	// Add the pipeline function to both the indexing pipeline and the
	// searching pipeline
	builder.pipeline.before(lunr.stemmer, pipelineFunction)
	builder.searchPipeline.before(lunr.stemmer, pipelineFunction)
}

window.autocomplete = function( elt ) {

	var input = $(elt);

	// init only once !
	if( input.data('autocomplete-init') ) {
		input.trigger('search');
		return;
	}
	input.data('autocomplete-init',true);

	// create dropdown-list and insert it after input
	var list = $('<ul class="dropdown-menu"></ul>').insertAfter(input);

	// init autocomplete with data
	var initAutocomplete = function( data ) {

		// create lunr index
		var idx = lunr(function () {
			this.ref('key')
			this.field('name');

			// modifiy index with lodash.deburr
			this.use(pipe);

			data.forEach(function (name, key) {
				this.add({ key: key, name: name });
			}, this);
		});


		// then, on keyup, search in index
		input.on('keyup.autocomplete', function(e) {

			// if key is arrow down
			if (e.keyCode == 40) {
				var active = list.find('.active').removeClass('active');
				active = active.closest('li').next('li').find('a').addClass('active');

				if( !active.length ) {
					active = list.find('li:first-child a').addClass('active');
				}
				return;
			}

			// if key is arrow up
			if (e.keyCode == 38) {
				var active = list.find('.active').removeClass('active');
				active = active.closest('li').prev('li').find('a').addClass('active');

				if( !active.length ) {
					active = list.find('li:last-child a').addClass('active');
				}
				return;
			}

			// if key is tab or enter
			if (e.keyCode == 9 || e.keyCode == 13) {
				var active = list.find('.active');
				if( active.length ) {
					var val = active.text();
					if( input.val() != val ) {
						e.preventDefault();
						input.val(val).trigger('change');
						return;
					}
				}
				return;
			}

			input.trigger('search');
		});


		input.on('search', function(e) {

			var val = input.val();
			var result = [];

			if( val )
			{
				// search index
				result = concat( idx.search( val ), idx.search( val+'*' ) );

				// map result to find name
				result = uniq( $.map(result, function(result) {
					return data[result.ref];
				}));
			}

			// empty list
			list.empty();

			// add first 10 results to list
			$.each(result.slice(0, 10), function() {
				$('<li><a class="dropdown-item" href="#" tabindex="-1">'+this+'</a></li>').on('click',function(e) {
					e.preventDefault();
					input.val(this.innerText).trigger('change');
				}).appendTo(list);
			});

			// if list is not empty, show it
			list.toggleClass('show', list.children().length > 0 );
		});

		input.on('blur.autocomplete', function(e) {
			setTimeout(function() {
				list.removeClass('show');
			}, 200);
		});
	}


	var href = input.data('autocomplete');

	if( !window.debug && sessionStorage.getItem(href) ) {
		return initAutocomplete( JSON.parse( sessionStorage.getItem(href) ) );
	}


	// get search data
	$.getJSON( href, function(data) {
		
		// save data to session
		sessionStorage.setItem(href, JSON.stringify(data));

		// init autocomplete
		initAutocomplete(data);
	});
}